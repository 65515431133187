"use client";

import { StartLSAResponse } from "@/app/api/willis/lsa/start/route";
import appConfig from "@/app/app.config";
import { normalizeApiUrl } from "@/app/utils/textUtils";
import { createContext, useCallback, useContext, useRef, useState, useEffect } from "react";
import { RealtimeEvent } from "../components/lsa/RealtimeEvent";
import { useLSAAudio } from "./LSAAudioContext";

// Add new types
type EventCallback = (event: RealtimeEvent) => void;
type SubscriptionId = string;
type EventMatcher = (event: RealtimeEvent) => boolean;

interface LSAConnectionContextType {
  startConnection: (sessionId: string, localStream: MediaStream) => Promise<void>;
  stopConnection: () => void;
  sendClientEvent: (event: RealtimeEvent) => void;
  sendTextMessage: (message: string) => void;
  isConnected: boolean;
  events: RealtimeEvent[];
  isSessionCreated: boolean;
  sendClientEventAndWait: (event: RealtimeEvent, waitForType: string) => Promise<RealtimeEvent>;
  waitForClientEvent: (waitForType: string) => Promise<RealtimeEvent>;
  subscribe: (eventType: string, callback: EventCallback) => SubscriptionId;
  unsubscribe: (subscriptionId: SubscriptionId) => void;
  monitorClientEventsOfType: (eventType: string, didMatch: EventMatcher) => Promise<RealtimeEvent>;
  reset: () => void;
}

const LSAConnectionContext = createContext<LSAConnectionContextType | null>(null);

export function LSAConnectionProvider({ children }: { children: React.ReactNode }) {
  const [isConnected, setIsConnected] = useState(false);
  const [isSessionCreated, setIsSessionCreated] = useState(false);
  const [events, setEvents] = useState<RealtimeEvent[]>([]);
  const { attachRemoteAudio } = useLSAAudio();

  const peerConnection = useRef<RTCPeerConnection | null>(null);
  const dataChannel = useRef<RTCDataChannel | null>(null);
  const subscriptions = useRef<Map<SubscriptionId, { type: string; callback: EventCallback }>>(new Map());
  const eventMonitors = useRef<
    Map<
      string,
      {
        resolve: (event: RealtimeEvent) => void;
        reject: (error: Error) => void;
        type: string;
        didMatch: EventMatcher;
      }
    >
  >(new Map());

  const sendClientEvent = useCallback((event: RealtimeEvent) => {
    if (dataChannel.current) {
      event.event_id = event.event_id || crypto.randomUUID();
      dataChannel.current.send(JSON.stringify(event));
      setEvents((prev) => [event, ...prev]);
    }
  }, []);

  const monitorClientEventsOfType = useCallback((eventType: string, didMatch: EventMatcher): Promise<RealtimeEvent> => {
    return new Promise((resolve, reject) => {
      const monitorId = crypto.randomUUID();

      // Store the monitor with its matcher function
      eventMonitors.current.set(monitorId, { resolve, reject, type: eventType, didMatch });

      // Set a timeout to reject the promise if no matching event is found
      setTimeout(() => {
        if (eventMonitors.current.has(monitorId)) {
          eventMonitors.current.delete(monitorId);
          reject(new Error(`Timeout waiting for matching event of type ${eventType}`));
        }
      }, 30000); // 30 second timeout
    });
  }, []);

  const waitForClientEvent = useCallback(
    (waitForType: string): Promise<RealtimeEvent> => {
      return monitorClientEventsOfType(waitForType, (event) => true);
    },
    [monitorClientEventsOfType],
  );

  const sendClientEventAndWait = useCallback(
    async (event: RealtimeEvent, waitForType: string): Promise<RealtimeEvent> => {
      // Start waiting for the response first
      const responsePromise = waitForClientEvent(waitForType);

      // Send the event using existing method
      sendClientEvent(event);

      // Wait for and return the response
      return responsePromise;
    },
    [waitForClientEvent, sendClientEvent],
  );

  const subscribe = useCallback((eventType: string, callback: EventCallback): SubscriptionId => {
    const id = crypto.randomUUID();
    subscriptions.current.set(id, { type: eventType, callback });
    return id;
  }, []);

  const unsubscribe = useCallback((subscriptionId: SubscriptionId) => {
    subscriptions.current.delete(subscriptionId);
  }, []);

  const handleIncomingEvent = useCallback((event: RealtimeEvent) => {
    // Add event to history
    setEvents((prev) => [event, ...prev]);


    // Notify any subscribers who are listening for this event type
    for (const [, subscription] of subscriptions.current.entries()) {
      if (subscription.type === event.type) {
        subscription.callback(event);
      }
    }

    // Check if any monitors match this event
    for (const [monitorId, monitor] of eventMonitors.current.entries()) {
      if (event.type === monitor.type && monitor.didMatch(event)) {
        monitor.resolve(event);
        eventMonitors.current.delete(monitorId);
        break; // Only resolve one monitor
      }
    }

    // Handle specific event types
    switch (event.type) {
      case "session.created":
        setIsSessionCreated(true);
        break;
      case "error":
        console.error("AI Error:", event);
        break;
    }
  }, []);

  const startConnection = useCallback(
    async (sessionId: string, localStream: MediaStream) => {
      try {
        // Create peer connection
        const pc = new RTCPeerConnection();
        peerConnection.current = pc;

        // Wire up event to set up remote audio handler
        pc.ontrack = (e) => {
          attachRemoteAudio(e.streams[0]);
        };

        // Add local audio track BEFORE creating offer
        if (localStream) {
          // From localStream.getTracks find the first track with readyState "live"
          const firstLiveTrack = localStream.getTracks().find((track) => track.readyState === "live");
          if (!firstLiveTrack) {
            console.error("No live track found in local stream");
            return;
          }

          pc.addTrack(firstLiveTrack, localStream);
        } else {
          console.error("No local stream found!!");
          return;
        }

        // Set up data channel
        const dc = pc.createDataChannel("oai-events");
        dataChannel.current = dc;

        dc.onmessage = (e) => {
          const event = JSON.parse(e.data);
          handleIncomingEvent(event);
        };

        dc.onopen = () => {
          setIsConnected(true);
          setEvents([]);
        };

        // Create and send offer
        const offer = await pc.createOffer();
        await pc.setLocalDescription(offer);

        // Get ephemeral key and make connection
        const apiUrl = normalizeApiUrl(appConfig.apiBasePath, "/willis/lsa/start");
        const data = await fetch(apiUrl, { method: "POST", body: JSON.stringify({ sessionId: sessionId }) });
        const response = (await data.json()) as StartLSAResponse;
        const EPHEMERAL_KEY = response.openAiEphemeralKey;

        const sdpResponse = await fetch(`${appConfig.ai.openaiBaseUrl}/realtime?model=${appConfig.ai.openaiModel}`, {
          method: "POST",
          body: offer.sdp,
          headers: {
            Authorization: `Bearer ${EPHEMERAL_KEY}`,
            "Content-Type": "application/sdp",
          },
        });

        const answer: RTCSessionDescriptionInit = {
          type: "answer",
          sdp: await sdpResponse.text(),
        };
        await pc.setRemoteDescription(answer);
      } catch (error) {
        console.error("Failed to start connection:", error);
        throw error;
      }
    },
    [attachRemoteAudio, handleIncomingEvent],
  );

  const reset = useCallback(() => {
    setEvents([]);
    setIsConnected(false);
    setIsSessionCreated(false);
    subscriptions.current.clear();
    eventMonitors.current.clear();
  }, []);

  const stopConnection = useCallback(() => {
    if (dataChannel.current) {
      dataChannel.current.close();
      dataChannel.current = null;
    }
    if (peerConnection.current) {
      peerConnection.current.close();
      peerConnection.current = null;
    }
    reset();
  }, [reset]);

  const sendTextMessage = useCallback(
    (message: string, role: "user" | "assistant" | "developer" = "user") => {
      const event: RealtimeEvent = {
        type: "conversation.item.create",
        item: {
          type: "message",
          role: role,
          content: [
            {
              type: "input_text",
              text: message,
            },
          ],
        },
      };
      sendClientEvent(event);
      sendClientEvent({ type: "response.create" });
    },
    [sendClientEvent],
  );

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stopConnection();
    };
  }, [stopConnection]);

  return (
    <LSAConnectionContext.Provider
      value={{
        startConnection,
        stopConnection,
        sendClientEvent,
        sendTextMessage,
        isConnected,
        isSessionCreated,
        events,
        sendClientEventAndWait,
        waitForClientEvent,
        subscribe,
        unsubscribe,
        monitorClientEventsOfType,
        reset,
      }}
    >
      {children}
    </LSAConnectionContext.Provider>
  );
}

export function useLSAConnection() {
  const context = useContext(LSAConnectionContext);
  if (!context) {
    throw new Error("useLSAConnection must be used within an LSAConnectionProvider");
  }
  return context;
}
