"use client";

import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";

interface LSAAudioContextType {
  startLocalAudio: () => Promise<void>;
  stopLocalAudio: () => void;
  attachRemoteAudio: (stream: MediaStream) => void;
  detachRemoteAudio: () => void;
  localStream: MediaStream | null;
  remoteStream: MediaStream | null;
  isLocalAudioActive: boolean;
  muteLocalAudio: () => void;
  unmuteLocalAudio: () => void;
  muteRemoteAudio: () => void;
  unmuteRemoteAudio: () => void;
  cleanup: () => void;
}

const LSAAudioContext = createContext<LSAAudioContextType | null>(null);

export function LSAAudioProvider({ children }: { children: React.ReactNode }) {
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const [isLocalAudioActive, setIsLocalAudioActive] = useState(false);
  const audioplaybackElement = useRef<HTMLAudioElement | null>(null);

  const startLocalAudio = useCallback(async () => {
    if (isLocalAudioActive) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setLocalStream(stream);
      setIsLocalAudioActive(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      throw error;
    }
  }, [isLocalAudioActive]);

  const stopLocalAudio = useCallback(() => {
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
      setLocalStream(null);
      setIsLocalAudioActive(false);
    }
  }, [localStream]);

  const attachRemoteAudio = useCallback((stream: MediaStream) => {
    if (!audioplaybackElement.current) {
      audioplaybackElement.current = document.createElement("audio");
      audioplaybackElement.current = new Audio();
      audioplaybackElement.current.autoplay = true;
    }
    audioplaybackElement.current.srcObject = stream;
    setRemoteStream(stream);
  }, []);

  const detachRemoteAudio = useCallback(() => {
    if (audioplaybackElement.current) {
      audioplaybackElement.current.srcObject = null;
    }
    if (remoteStream) {
      remoteStream.getTracks().forEach((track) => track.stop());
      setRemoteStream(null);
    }
  }, [remoteStream]);

  const muteLocalAudio = useCallback(() => {
    if (localStream) {
      localStream.getTracks()
        .filter(track => track.readyState === "live")
        .forEach(track => track.enabled = false);
    }
  }, [localStream]);

  const unmuteLocalAudio = useCallback(() => {
    if (localStream) {
      localStream.getTracks()
        .filter(track => track.readyState === "live")
        .forEach(track => track.enabled = true);
    }
  }, [localStream]);

  const muteRemoteAudio = useCallback(() => {
    if (remoteStream) {
      remoteStream.getTracks()
        .filter(track => track.readyState === "live")
        .forEach(track => track.enabled = false);
    }
  }, [remoteStream]);

  const unmuteRemoteAudio = useCallback(() => {
    if (remoteStream) {
      remoteStream.getTracks()
        .filter(track => track.readyState === "live")
        .forEach(track => track.enabled = true);
    }
  }, [remoteStream]);

  const cleanup = useCallback(() => {
    // Stop and cleanup local stream
    if (localStream) {
      localStream.getTracks().forEach(track => {
        track.stop();
        track.enabled = false;
        localStream.removeTrack(track);
      });
      setLocalStream(null);
      setIsLocalAudioActive(false);
    }

    // Stop and cleanup remote stream
    if (remoteStream) {
      remoteStream.getTracks().forEach(track => {
        track.stop();
        track.enabled = false;
        remoteStream.removeTrack(track);
      });
      setRemoteStream(null);
    }

    // Cleanup audio element
    if (audioplaybackElement.current) {
      audioplaybackElement.current.srcObject = null;
      audioplaybackElement.current.remove();
      audioplaybackElement.current = null;
    }
  }, [localStream, remoteStream]);

  return (
    <LSAAudioContext.Provider
      value={{
        startLocalAudio,
        stopLocalAudio,
        attachRemoteAudio,
        detachRemoteAudio,
        localStream,
        remoteStream,
        isLocalAudioActive,
        muteLocalAudio,
        unmuteLocalAudio,
        muteRemoteAudio,
        unmuteRemoteAudio,
        cleanup,
      }}
    >
      {children}
    </LSAAudioContext.Provider>
  );
}

export function useLSAAudio() {
  const context = useContext(LSAAudioContext);
  if (!context) {
    throw new Error("useLSAAudio must be used within an LSAAudioProvider");
  }
  return context;
}
