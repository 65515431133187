"use client";

import React, { createContext, useContext, useState, ReactNode } from "react";

interface TitleBarState {
  isVisible: boolean;
  title?: string | null;
  backHref?: string | null;
}

interface TitleBarContextProps extends TitleBarState {
  showBar: () => void;
  hideBar: () => void;
  changeBackHref: (href: string | null) => void;
  changeTitle: (href: string | null) => void;
  initBar: (title: string, backHref: string | null) => void;
}

const TitleBarContext = createContext<TitleBarContextProps | undefined>(
  undefined,
);

export const TitleBarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [backHref, setBackHref] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>("");

  const showBar = () => setIsVisible(true);
  const hideBar = () => setIsVisible(false);
  const changeTitle = setTitle;
  const changeBackHref = setBackHref;
  const initBar = (title: string, backHref: string | null) => {
    setIsVisible(true);
    setTitle(title);
    setBackHref(backHref);
  };

  return (
    <TitleBarContext.Provider
      value={{
        isVisible,
        backHref,
        title,
        initBar,
        showBar,
        hideBar,
        changeBackHref,
        changeTitle,
      }}
    >
      {children}
    </TitleBarContext.Provider>
  );
};

export const useTitleBar = () => {
  const context = useContext(TitleBarContext);
  if (!context) {
    throw new Error("useTitleBar must be used within a TitleBarProvider");
  }
  return context;
};
