export const normalizeApiUrl = (baseUrl: string|undefined, relativePath: string|undefined): string => {
    if (!baseUrl) {
        throw new Error("Base URL is required - check environment variables");
    }
    if (!relativePath) {
        throw new Error("Relative path is required");
    }
  const normalizedBaseUrl = baseUrl.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl; // Remove trailing slash if present
  const normalizedPath = relativePath.startsWith("/") ? relativePath : `/${relativePath}`; // Ensure the relative path starts with a slash
  return `${normalizedBaseUrl}${normalizedPath}`; // Combine base URL and relative path
};

export const generateRandomString = (length: number = 5, 
                                     characters: string = 'abcdefghijklmnopqrstuvwxyz0123456789') => {
    let result = ''
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result
}
