"use client";

import { ClerkProvider } from "@clerk/nextjs";
import { DebProvider } from "../willis/services/useMiniDeb";
import { TitleBarProvider } from "../willis/services/useTitleBar";
import { LSAAudioProvider } from "../willis/contexts/LSAAudioContext";
import { LSAConnectionProvider } from "../willis/contexts/LSAConnectionContext";
import { LSAActivityProvider } from "../willis/contexts/LSAActivityContext";

export function AppProviders({ children }: { children: React.ReactNode }) {
  return (
    <ClerkProvider>
      <DebProvider>
        <TitleBarProvider>
          <LSAAudioProvider>
            <LSAConnectionProvider>
              <LSAActivityProvider>
                {/* It is ok to pass server components as children and they will remain server components */}
                {children}
              </LSAActivityProvider>
            </LSAConnectionProvider>
          </LSAAudioProvider>
        </TitleBarProvider>
      </DebProvider>
    </ClerkProvider>
  );
}

export default AppProviders;
