export interface AIAppConfig {
  openaiApiKey: string;
  openaiBaseUrl: string;
  openaiRealtimeModel: string;
  openaiDefaultVoiceName: string;
  openaiModel: string;
}

export interface UIAppConfig {
  showMobileScrollbarsInDevelopmentEnv: boolean;
}

export interface AppConfig {
  apiBasePath: string;
  uploadBasePath: string; // Server-specific, placeholder value
  publicBasePath: string; // Server-specific, placeholder value
  databaseUri: string;
  maxSkillLevel: number;
  ai: AIAppConfig;
  ui: UIAppConfig;
}

export const appConfig: AppConfig = {
  apiBasePath: process.env.NEXT_PUBLIC_API_BASE_PATH || "/api",
  uploadBasePath: "SERVER_ONLY_PLACEHOLDER", // Placeholder to indicate server-only
  publicBasePath: "SERVER_ONLY_PLACEHOLDER", // Placeholder to indicate server-only
  databaseUri: process.env.DATABASE_URI || "",
  maxSkillLevel: Number.parseInt(process.env.MAXIMUM_SKILL_LEVEL || "25"),
  ai: {
    openaiApiKey: process.env.OPENAI_API_KEY || "",
    openaiBaseUrl: process.env.OPENAI_BASE_URL || "https://api.openai.com/v1",
    openaiDefaultVoiceName: process.env.OPENAI_DEFAULT_VOICE_NAME || "verse",
    openaiRealtimeModel: process.env.OPENAI_REALTIME_MODEL || "gpt-4o-mini-realtime-preview-2024-12-17",
    openaiModel: process.env.OPENAI_MODEL || "gpt-4o-mini-preview-2024-12-17",
  },
  ui: {
    // Set to true to aid in local development and design/layouting
    showMobileScrollbarsInDevelopmentEnv: false,
  },
};

export default appConfig;
