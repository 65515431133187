"use client";
import { createContext, useCallback, useContext, useState } from "react";
import { useLSAConnection } from "./LSAConnectionContext";
import { END_OF_ROLEPLAY_TOKEN } from "@/app/utils/lsaPromptConstants";
import { RealtimeEvent, ResponseEvent } from "../components/lsa/RealtimeEvent";
import { ScoreResponse } from "@/app/api/willis/lsa/scoring/scoreResponse";

interface LSAActivityContextType {
  startScoring: (prompt: string) => Promise<void>;
  scoreResponse: ScoreResponse | null;
}

const LSAActivityContext = createContext<LSAActivityContextType | null>(null);

export function LSAActivityProvider({ children }: { children: React.ReactNode }) {
  const { sendClientEventAndWait, sendClientEvent, monitorClientEventsOfType } = useLSAConnection();
  const [scoreResponse, setScoreResponse] = useState<ScoreResponse | null>(null);

  const startScoring = useCallback(
    async (scoringPrompt: string) => {
      await sendClientEvent({
        type: "response.create",
        response: {
          conversation: "none", // Setting to "none" indicates the response is out of band and will not be added to the default conversation
          metadata: { topic: "scoringRequest" },  // Set metadata to help identify responses sent back from the model
          modalities: ["text"],
          instructions: `
          ${END_OF_ROLEPLAY_TOKEN}
          
          ${scoringPrompt}`,
        },
      });

      // Wait for a specific event with a custom condition
      const responseCreatedEvent = await monitorClientEventsOfType(
        "response.created",
        (event: ResponseEvent) => event.response?.metadata?.topic === "scoringRequest",
      );
      const responseId = responseCreatedEvent.response?.id;
      const responseDoneEvent = (await monitorClientEventsOfType(
        "response.text.done",
        (event: ResponseEvent) => event.response_id === responseId,
      )) as ResponseEvent;

      if (responseDoneEvent) {
        if (!responseDoneEvent.text) {
          throw new Error("No text in responseDoneEvent - cannot score the activity.");
        }

        const parsedResponse: ScoreResponse = JSON.parse(responseDoneEvent.text);
        setScoreResponse(parsedResponse);
      }
    },
    [sendClientEvent, monitorClientEventsOfType],
  );

  return (
    <LSAActivityContext.Provider value={{ startScoring, scoreResponse }}>
      {children}
    </LSAActivityContext.Provider>
  );
}

export function useLSAActivity() {
  const context = useContext(LSAActivityContext);
  if (!context) {
    throw new Error("useLSAActivity must be used within an LSAActivityProvider");
  }
  return context;
}
